<template>
  <v-card class="ma-0 pa-0">
    <v-card-text>
      <div class="d-sm-flex align-center">
        <div>
          <h2 class="font-weight-regular title">{{ $t('Patient.TopPatients') }}</h2>
          <h6 class="body-2 mb-1 subtitle-2 font-weight-light">
            {{ $t('Patient.CalculatedAt') }} {{ todayString }}
          </h6>
        </div>
        <v-spacer></v-spacer>
      </div>
      <!-- Table -->
      <v-skeleton-loader :loading="isSkeletonLoading" height="260" type="table">
        <v-simple-table class="month-table mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="head in heads"
                  :key="head.text"
                  class="blue-grey--text text--lighten-2 font-weight-medium subtitle-2 text-left"
                >
                  {{ head.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in data"
                :key="item.text"
                class="month-item"
              >
                <td>
                  <h5 class="subtitle-1 font-weight-regular">
                    # {{ index + 1 }}
                  </h5>
                </td>
                <td>
                  <v-avatar size="32px" color="primary">
                    <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
                    <span v-else class="white--text">
                      {{ getNameInnitial(item.fullName) }}
                    </span>
                  </v-avatar>
                </td>
                <td>
                  <h6 class="subtitle-2 text-no-wrap">{{ item.fullName }}</h6>
                  <small
                    class="blue-grey--text text--lighten-2 font-weight-light"
                    >{{ item.phone }}</small
                  >
                </td>
                <td>
                  <h5 class="subtitle-1 font-weight-regular">
                    <GenderItem :genderType="item.genderType" />
                  </h5>
                </td>
                <td>
                  <h5 class="subtitle-1 font-weight-regular">
                    {{ item.countAdmissions }}
                  </h5>
                </td>
                <td>
                  <h5 class="subtitle-1 font-weight-regular">
                    {{ item.priceString }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import ReportService from "../../services/pas-report";
import GenderItem from "../../components/commonComponents/Queue/components/GenderItem";
import { convertPriceString, getNameInnitial } from "../../plugins/helper";
import i18n from "@/plugins/i18n";

export default {
  components: {
    GenderItem,
  },
  data: () => ({
    isSkeletonLoading: true,
    todayString: moment().format("LLL"),
    heads: [
      { text: i18n.t("Common.Top") },
      { text: i18n.t("Patient.Avatar") },
      { text: i18n.t("Patient.FullName") },
      { text: i18n.t("Patient.Gender") },
      { text: i18n.t("Admission.Admissions") },
      { text: i18n.t("Revenue.TotalPrice") },
    ],
    data: [],
  }),
  created() {
    this.render();
  },
  methods: {
    getNameInnitial,
    async render() {
      this.isSkeletonLoading = true;
      var result = await ReportService.getTopPayingPatients(4);
      this.isSkeletonLoading = false;
      if (!result)
        this.$emit("onError", "Failed to load top paying patients statistics!");
      result.forEach((p) => {
        p.priceString = convertPriceString(p.countTotalPrice);
      });
      this.data = result;
    },
  },
};
</script>
<style scoped>
tr td > * {
  padding: 5px;
}
</style>